
const mockup_external = {
    "list": {
        "pagination": {
            "count": 100,
            "hasMoreItems": true,
            "totalItems": 1001,
            "skipCount": 0,
            "maxItems": 100
        },
        "entries": [
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "admin",
                        "displayName": "Administrator"
                    },
                    "modifiedAt": "2015-07-22T13:08:48.387+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "application/eps",
                        "mimeTypeName": "EPS Type PostScript",
                        "sizeInBytes": 191355,
                        "encoding": "UTF-8"
                    },
                    "parentId": "5c9295e3-ec98-414a-94b7-0cf8f7309bfa",
                    "createdAt": "2015-07-22T13:08:48.387+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "admin",
                        "displayName": "Administrator"
                    },
                    "name": "Untitled-1.eps",
                    "location": "nodes",
                    "id": "942ecc1b-4085-4ec1-bbe4-46cd605b2c78",
                    "properties": {
                        "macgh:id": "11",
                        "macgh:idioma": "111",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "11",
                        "macgh:fecha_publicacion": "2015-07-30T22:00:00.000+0000",
                        "macgh:area_escolar": "bachillerato",
                        "macgh:familia": "sin_familia",
                        "macgh:division": "escolar",
                        "macgh:proy_edit_nombre": "in2",
                        "macgh:anyo": "2015",
                        "cm:lastThumbnailModification": [
                            "doclib:1437570530402"
                        ],
                        "macgh:tipo_proyecto": "digital"
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "admin",
                        "displayName": "Administrator"
                    },
                    "modifiedAt": "2015-07-23T07:11:00.666+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "application/x-indesign",
                        "mimeTypeName": "Adobe InDesign Document",
                        "sizeInBytes": 12152832,
                        "encoding": "UTF-8"
                    },
                    "parentId": "5c9295e3-ec98-414a-94b7-0cf8f7309bfa",
                    "createdAt": "2015-07-23T07:11:00.666+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "admin",
                        "displayName": "Administrator"
                    },
                    "name": "Ejemplo INDESIGN.indd",
                    "location": "nodes",
                    "id": "0cf7a073-54fa-4879-8aec-54d064f25411",
                    "properties": {
                        "macgh:id": "11",
                        "macgh:idioma": "111",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "11",
                        "macgh:fecha_publicacion": "2015-07-30T22:00:00.000+0000",
                        "macgh:area_escolar": "bachillerato",
                        "macgh:familia": "sin_familia",
                        "macgh:division": "escolar",
                        "macgh:proy_edit_nombre": "in2",
                        "macgh:anyo": "2015",
                        "macgh:tipo_proyecto": "digital"
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "admin",
                        "displayName": "Administrator"
                    },
                    "modifiedAt": "2018-05-18T08:35:58.291+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "application/illustrator",
                        "mimeTypeName": "Adobe Illustrator File",
                        "sizeInBytes": 1102214,
                        "encoding": "UTF-8"
                    },
                    "parentId": "9af65300-6842-48f9-b0b2-57893ef4d02f",
                    "createdAt": "2015-08-03T11:24:08.807+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "admin",
                        "displayName": "Administrator"
                    },
                    "name": "4819596_esq.01_02.ai",
                    "location": "nodes",
                    "id": "617fb032-94ad-4f13-be63-ce427ac3c43a",
                    "properties": {
                        "cm:title": "4819596_esq.01_02",
                        "macgh:id": "9788448195960",
                        "macgh:idioma": "CAST",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448195965",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2015",
                        "cm:lastThumbnailModification": [
                            "webpreview:1445949853277"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2015-02-25T23:00:00.000+0000",
                        "macgh:area_escolar": "bachillerato",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "8448195965_Economía_1_Bach"
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "admin",
                        "displayName": "Administrator"
                    },
                    "modifiedAt": "2021-03-16T23:14:30.239+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 68218,
                        "encoding": "UTF-8"
                    },
                    "parentId": "b3e7f5dd-ac4f-487d-b75b-660c2a1ededc",
                    "createdAt": "2015-10-27T12:42:39.315+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "admin",
                        "displayName": "Administrator"
                    },
                    "name": "alfresco_logo.jpg",
                    "location": "nodes",
                    "id": "fd884333-9bf5-4cd1-8758-5a67dd657c95",
                    "properties": {
                        "macgh:id": "9788448195960",
                        "macgh:idioma": "CAST",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448195965",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2015",
                        "exif:pixelXDimension": 1600,
                        "cm:lastThumbnailModification": [
                            "doclib:1445949762743",
                            "imgpreview:1445949786353"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2015-02-25T23:00:00.000+0000",
                        "macgh:area_escolar": "bachillerato",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Economía_1_Bach",
                        "exif:pixelYDimension": 460
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:08:02.916+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 14667,
                        "encoding": "UTF-8"
                    },
                    "parentId": "c8c6521e-c6b2-42eb-8887-307dc5481170",
                    "createdAt": "2016-01-08T10:07:42.930+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "5.7_22914624.jpg",
                    "location": "nodes",
                    "id": "9d769b93-1e8a-4245-b0d0-8180ad303e69",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247682360"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 600
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:08:03.417+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 23300,
                        "encoding": "UTF-8"
                    },
                    "parentId": "c8c6521e-c6b2-42eb-8887-307dc5481170",
                    "createdAt": "2016-01-08T10:07:44.550+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 6_18815468.jpg",
                    "location": "nodes",
                    "id": "b8c11cd4-be5f-43de-a348-d33b861ee692",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247682828"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 600
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:08:00.956+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 53035,
                        "encoding": "UTF-8"
                    },
                    "parentId": "c8c6521e-c6b2-42eb-8887-307dc5481170",
                    "createdAt": "2016-01-08T10:07:45.610+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 7_8883334.jpg",
                    "location": "nodes",
                    "id": "1dfea043-a2c8-45ca-9de4-6527c791cf3d",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247678888"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 600
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:08:01.947+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 28723,
                        "encoding": "UTF-8"
                    },
                    "parentId": "c8c6521e-c6b2-42eb-8887-307dc5481170",
                    "createdAt": "2016-01-08T10:07:46.647+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 11_6745803.jpg",
                    "location": "nodes",
                    "id": "598c3d76-d4ae-4722-b29c-a8513ba0db75",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247678888"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 400
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:08:00.956+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 16975,
                        "encoding": "UTF-8"
                    },
                    "parentId": "c8c6521e-c6b2-42eb-8887-307dc5481170",
                    "createdAt": "2016-01-08T10:07:47.798+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 14_13713671.jpg",
                    "location": "nodes",
                    "id": "54715cca-ba73-4da1-a2ac-3fad0175125c",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247678888"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 400
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:08:03.373+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/png",
                        "mimeTypeName": "PNG Image",
                        "sizeInBytes": 19567,
                        "encoding": "UTF-8"
                    },
                    "parentId": "c8c6521e-c6b2-42eb-8887-307dc5481170",
                    "createdAt": "2016-01-08T10:07:48.836+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 19_Franqueo-Pagado.png",
                    "location": "nodes",
                    "id": "9ba1dd66-89cc-4624-b376-aee7b8b56219",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 423,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247682831"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 176
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:08:00.956+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 21609,
                        "encoding": "UTF-8"
                    },
                    "parentId": "c8c6521e-c6b2-42eb-8887-307dc5481170",
                    "createdAt": "2016-01-08T10:07:49.768+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 20_7362453.jpg",
                    "location": "nodes",
                    "id": "37ae433c-7074-4290-bf4b-91b4faa91dc1",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247678888"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 400
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:08:02.819+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 31714,
                        "encoding": "UTF-8"
                    },
                    "parentId": "c8c6521e-c6b2-42eb-8887-307dc5481170",
                    "createdAt": "2016-01-08T10:07:51.860+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "5.0_7238058.jpg",
                    "location": "nodes",
                    "id": "39e55147-3040-4557-bb69-73546cbe3af5",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247681834"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 450
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:08:01.894+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 18319,
                        "encoding": "UTF-8"
                    },
                    "parentId": "c8c6521e-c6b2-42eb-8887-307dc5481170",
                    "createdAt": "2016-01-08T10:07:53.028+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "5.3_8773216.jpg",
                    "location": "nodes",
                    "id": "f55859b0-62c2-4316-bce9-937fcc288a50",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247678888"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 570
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:08:01.944+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 32635,
                        "encoding": "UTF-8"
                    },
                    "parentId": "c8c6521e-c6b2-42eb-8887-307dc5481170",
                    "createdAt": "2016-01-08T10:07:54.268+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "5.4_23229495.jpg",
                    "location": "nodes",
                    "id": "d393bbdc-48ed-4488-8d5c-0e6d5eeb7675",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247678888"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 460
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:08:00.956+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 18098,
                        "encoding": "UTF-8"
                    },
                    "parentId": "c8c6521e-c6b2-42eb-8887-307dc5481170",
                    "createdAt": "2016-01-08T10:07:55.314+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "5.5_17669815.jpg",
                    "location": "nodes",
                    "id": "f6d46840-6b97-4580-a83c-244361361b9c",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247678888"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 450
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:08:03.428+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 24958,
                        "encoding": "UTF-8"
                    },
                    "parentId": "c8c6521e-c6b2-42eb-8887-307dc5481170",
                    "createdAt": "2016-01-08T10:07:56.272+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "5.6_40903027.jpg",
                    "location": "nodes",
                    "id": "b61bd634-23f3-4a2d-b5c2-8b15840a6be2",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247682965"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 600
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:08:49.762+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "application/vnd.ms-excel",
                        "mimeTypeName": "Microsoft Excel",
                        "sizeInBytes": 40960,
                        "encoding": "UTF-8"
                    },
                    "parentId": "c8c6521e-c6b2-42eb-8887-307dc5481170",
                    "createdAt": "2016-01-08T10:08:38.942+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "Figuras_UD05.xls",
                    "location": "nodes",
                    "id": "3b43d240-73ce-43d0-9bca-677a7adb3128",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "cm:lastThumbnailModification": [
                            "doclib:1452247728529"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "cm:author": "vales",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto"
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:54.577+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 22337,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:16.480+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "Tabla 6.2.i_43131508.jpg",
                    "location": "nodes",
                    "id": "f625f99c-0eb6-48f2-bfa3-7b4f5def2df4",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247794390"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 355
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:54.746+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 18983,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:18.151+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "Tabla 6.4.a_18056156.jpg",
                    "location": "nodes",
                    "id": "ec020e69-b8d4-491a-9be1-ed6917cbdbf9",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247794576"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 403
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:54.258+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 13832,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:19.665+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "Tabla 6.4.b_20705937.jpg",
                    "location": "nodes",
                    "id": "9d8fe8f5-1bcd-4b08-bf89-2acb95b8ded3",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247794075"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 450
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:52.408+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 23441,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:20.557+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "Tabla 6.4.c_46240260.jpg",
                    "location": "nodes",
                    "id": "28f903e9-44c6-49aa-a313-b8ebc6b1fa84",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247791960"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 415
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:52.151+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 20604,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:21.493+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "Tabla 6.4.d_47014769.jpg",
                    "location": "nodes",
                    "id": "0c77ca3f-38f0-4ff4-ac32-6d8b267281ae",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247791724"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 438
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:54.392+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 22062,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:22.347+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "Tabla 6.4.e_46420902.jpg",
                    "location": "nodes",
                    "id": "259be51f-4f5c-417c-8ed1-254a555962dd",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247794277"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 400
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:54.229+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 16294,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:23.489+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "Tabla 6.4.f_43662752.jpg",
                    "location": "nodes",
                    "id": "228eadb9-f58c-4c0f-a5cf-066f74e2cc11",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247794005"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 480
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:54.535+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 25552,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:24.436+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "Tabla 6.4.g_31776890.jpg",
                    "location": "nodes",
                    "id": "4aed8cdd-6a25-4b43-9b22-7059f519b686",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247794343"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 400
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:54.475+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 19261,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:25.337+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "Tabla 6.4.h_15103901.jpg",
                    "location": "nodes",
                    "id": "fdb4262f-288f-48d2-af99-4bbb2bc5860e",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247794325"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 535
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:52.053+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 25687,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:26.241+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "6.0_12619620.jpg",
                    "location": "nodes",
                    "id": "91225a1e-8958-4043-b41d-7dc27f621f30",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247791163"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 546
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:52.148+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 18013,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:27.200+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "6.3_27081253.jpg",
                    "location": "nodes",
                    "id": "9d87e5d2-405f-40f8-b6cd-1f84c8d77460",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 400,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247791858"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 600
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:52.053+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 26152,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:28.142+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 2_11529705.jpg",
                    "location": "nodes",
                    "id": "4ed51b37-2028-453b-b89a-5bc81efc17c5",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247791593"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 400
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:53.206+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 17827,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:29.371+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 5_18787897.jpg",
                    "location": "nodes",
                    "id": "dde98721-e43a-46e2-9159-314b6b21a556",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247793018"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 349
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:53.338+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 19578,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:30.314+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 7_16575853.jpg",
                    "location": "nodes",
                    "id": "90bc0d60-6a6c-429c-859f-f6cb26ab6bef",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247793052"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 489
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:52.054+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 13270,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:31.360+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 11_34337397.jpg",
                    "location": "nodes",
                    "id": "34ec3e75-88d6-4d2f-a082-c4494160ff8e",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247791574"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 399
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:52.109+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 20621,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:32.506+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 18_20563833.jpg",
                    "location": "nodes",
                    "id": "6a3f89e2-ad10-481f-9728-5cc118ea87ae",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 459,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247791578"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 600
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:52.106+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 25781,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:33.397+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 21_20622572.jpg",
                    "location": "nodes",
                    "id": "fd18d363-292d-47cd-ae47-22892766c2ab",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247791550"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 600
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:53.348+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 22382,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:34.336+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 22_14287486.jpg",
                    "location": "nodes",
                    "id": "a145de26-a3d0-406a-97de-c2652f4a51c5",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247793052"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 399
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:52.157+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 15604,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:35.138+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 24_32548560.jpg",
                    "location": "nodes",
                    "id": "c48287a0-18d1-478b-ba07-a2c2eff83215",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247791806"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 600
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:52.151+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 30632,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:36.032+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 27_12824635.jpg",
                    "location": "nodes",
                    "id": "138ba51a-7e8d-4389-8adf-71039c6f9026",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 474,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247791957"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 600
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:52.413+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 23829,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:36.840+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 28_29459904.jpg",
                    "location": "nodes",
                    "id": "22018465-083a-4387-a7d2-9236b449ff16",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 589,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247792000"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 600
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:53.350+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 12428,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:38.012+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "Tabla 6.1.a_3671577.jpg",
                    "location": "nodes",
                    "id": "e772b9dd-ac19-4898-8e25-ce45a6f1e98c",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 418,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247793199"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 600
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:53.340+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 17307,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:38.876+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "Tabla 6.1.b_28505603.jpg",
                    "location": "nodes",
                    "id": "5e6ac380-24bb-4d88-b68a-e9ed800281c3",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 400,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247793052"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 600
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:53.233+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 26627,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:40.323+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "Tabla 6.1.c_14428690.jpg",
                    "location": "nodes",
                    "id": "cab1a1ac-5485-4602-a22b-b2553fa71f2e",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 400,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247793074"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 600
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:53.942+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 18662,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:41.197+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "Tabla 6.2.a_32857883.jpg",
                    "location": "nodes",
                    "id": "da8f581b-d9f1-4354-bfde-ab3ace37baa1",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247793786"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 400
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:53.943+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 26929,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:42.024+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "Tabla 6.2.b_22310566.jpg",
                    "location": "nodes",
                    "id": "3b266b7d-80a0-4822-80a5-f8671c35720c",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247793785"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 384
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:53.779+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 24456,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:42.908+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "Tabla 6.2.c_36874882.jpg",
                    "location": "nodes",
                    "id": "30593007-20b0-41bd-bf0e-7a16adf9cfc3",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247793540"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 600
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:54.921+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 28379,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:43.789+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "Tabla 6.2.d_38676562.jpg",
                    "location": "nodes",
                    "id": "bb50ccc3-d4f4-47cf-9b77-598b591dd4c9",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247794777"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 427
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:54.917+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 14511,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:44.731+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "Tabla 6.2.e_14757912.jpg",
                    "location": "nodes",
                    "id": "222dd596-320f-497d-9560-a684837e813d",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 601,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247794773"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 601
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:54.208+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 14573,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:45.792+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "Tabla 6.2.f_15689472.jpg",
                    "location": "nodes",
                    "id": "1e0c8a4f-b870-4d76-91e1-3ca56fa90338",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247793992"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 406
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:53.222+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 21101,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:46.696+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "Tabla 6.2.g_10671593.jpg",
                    "location": "nodes",
                    "id": "a9918842-5de2-444d-9f42-ffdc0cdf7258",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247793074"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 400
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:09:53.822+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 31387,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:09:47.742+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "Tabla 6.2.h_37758223.jpg",
                    "location": "nodes",
                    "id": "c9dfb62e-8cc7-44ea-b0dc-b421f661cf8d",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452247793622"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 450
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T10:11:29.808+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "application/vnd.ms-excel",
                        "mimeTypeName": "Microsoft Excel",
                        "sizeInBytes": 43520,
                        "encoding": "UTF-8"
                    },
                    "parentId": "d9f9f28e-13b9-48dd-b583-436ba20ecd28",
                    "createdAt": "2016-01-08T10:11:24.311+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "Figuras_UD06.xls",
                    "location": "nodes",
                    "id": "2ab0283a-3ea1-43ec-9887-9f4ca09c95fb",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "cm:lastThumbnailModification": [
                            "doclib:1452247889715"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "cm:author": "vales",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto"
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T11:29:41.668+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "application/vnd.ms-excel",
                        "mimeTypeName": "Microsoft Excel",
                        "sizeInBytes": 44032,
                        "encoding": "UTF-8"
                    },
                    "parentId": "32503920-9eda-4afa-a1fd-ac5ba586d7b3",
                    "createdAt": "2016-01-08T11:29:36.152+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "Figuras_UD02.xls",
                    "location": "nodes",
                    "id": "64f23123-398b-4bb1-9084-824c1dc299e5",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "cm:lastThumbnailModification": [
                            "doclib:1452252580397"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "cm:author": "vales",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto"
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T11:30:21.055+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 21351,
                        "encoding": "UTF-8"
                    },
                    "parentId": "32503920-9eda-4afa-a1fd-ac5ba586d7b3",
                    "createdAt": "2016-01-08T11:29:44.777+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 19_21380363.jpg",
                    "location": "nodes",
                    "id": "1190824d-3f50-4f7f-8eb4-c3c5b2f5dfc3",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452252620904"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 400
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T11:30:20.678+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 15934,
                        "encoding": "UTF-8"
                    },
                    "parentId": "32503920-9eda-4afa-a1fd-ac5ba586d7b3",
                    "createdAt": "2016-01-08T11:29:46.749+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 21. Act22_a_18868456.jpg",
                    "location": "nodes",
                    "id": "22b09771-4752-4e72-be6d-c75ca5e01efe",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 448,
                        "cm:lastThumbnailModification": [
                            "doclib:1452252620050"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 600
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T11:30:20.929+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 13395,
                        "encoding": "UTF-8"
                    },
                    "parentId": "32503920-9eda-4afa-a1fd-ac5ba586d7b3",
                    "createdAt": "2016-01-08T11:29:47.730+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 21. Act22_b_16757278.jpg",
                    "location": "nodes",
                    "id": "50470b2e-0024-459e-ab3d-9d5b087f4b75",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452252620775"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 400
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T11:30:19.765+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 15047,
                        "encoding": "UTF-8"
                    },
                    "parentId": "32503920-9eda-4afa-a1fd-ac5ba586d7b3",
                    "createdAt": "2016-01-08T11:29:48.877+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 21. Act22_c_28130545.jpg",
                    "location": "nodes",
                    "id": "4664d6b2-26f7-4e29-88f3-a9e46f0bf9f0",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452252619438"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 483
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T11:30:19.217+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 15599,
                        "encoding": "UTF-8"
                    },
                    "parentId": "32503920-9eda-4afa-a1fd-ac5ba586d7b3",
                    "createdAt": "2016-01-08T11:29:49.987+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 21. Act22_d_19810472.jpg",
                    "location": "nodes",
                    "id": "179c006f-ad1f-476a-9a67-03753b555bb1",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 400,
                        "cm:lastThumbnailModification": [
                            "doclib:1452252618826"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 600
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T11:30:19.250+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 111683,
                        "encoding": "UTF-8"
                    },
                    "parentId": "32503920-9eda-4afa-a1fd-ac5ba586d7b3",
                    "createdAt": "2016-01-08T11:29:52.054+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 21. Act23_41004639.jpg",
                    "location": "nodes",
                    "id": "cab69c71-77bc-444d-9606-d1fc340c2468",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452252618950"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 600
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T11:30:18.888+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 443172,
                        "encoding": "UTF-8"
                    },
                    "parentId": "32503920-9eda-4afa-a1fd-ac5ba586d7b3",
                    "createdAt": "2016-01-08T11:29:56.871+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 21. Act23_41004639_XL.jpg",
                    "location": "nodes",
                    "id": "b5022c51-77cd-46e4-a312-c3b78e510f26",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 3128,
                        "cm:lastThumbnailModification": [
                            "doclib:1452252617431"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 3128
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T11:30:20.139+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 300510,
                        "encoding": "UTF-8"
                    },
                    "parentId": "32503920-9eda-4afa-a1fd-ac5ba586d7b3",
                    "createdAt": "2016-01-08T11:29:58.137+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 22. CP 10_12897535_L.jpg",
                    "location": "nodes",
                    "id": "e821c2b6-2282-433c-9cd2-2b46cdaa39d8",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 2360,
                        "cm:lastThumbnailModification": [
                            "doclib:1452252619824"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 1777
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T11:30:18.409+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 24991,
                        "encoding": "UTF-8"
                    },
                    "parentId": "32503920-9eda-4afa-a1fd-ac5ba586d7b3",
                    "createdAt": "2016-01-08T11:29:59.138+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 22_21557243.jpg",
                    "location": "nodes",
                    "id": "ed299247-6c41-424f-a3dd-36be2bb0143e",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452252617060"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 400
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T11:30:31.725+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 23751,
                        "encoding": "UTF-8"
                    },
                    "parentId": "32503920-9eda-4afa-a1fd-ac5ba586d7b3",
                    "createdAt": "2016-01-08T11:30:00.153+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "2.0_41340384.jpg",
                    "location": "nodes",
                    "id": "f6d932d5-7ca6-4372-8402-ec8abcd5e121",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452252616776",
                            "imgpreview:1452252631625"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 534
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T11:30:20.653+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 17502,
                        "encoding": "UTF-8"
                    },
                    "parentId": "32503920-9eda-4afa-a1fd-ac5ba586d7b3",
                    "createdAt": "2016-01-08T11:30:01.275+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "2.1_4822301.jpg",
                    "location": "nodes",
                    "id": "4a69f0d2-ef30-4a20-8b87-6c7a5f715a5b",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452252619935"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 402
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T11:30:18.409+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 30592,
                        "encoding": "UTF-8"
                    },
                    "parentId": "32503920-9eda-4afa-a1fd-ac5ba586d7b3",
                    "createdAt": "2016-01-08T11:30:02.956+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "2.3_26730085.jpg",
                    "location": "nodes",
                    "id": "60da9c6d-468e-4872-9627-9cf73550e214",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452252616825"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 426
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T11:30:20.936+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 27774,
                        "encoding": "UTF-8"
                    },
                    "parentId": "32503920-9eda-4afa-a1fd-ac5ba586d7b3",
                    "createdAt": "2016-01-08T11:30:04.325+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "2.4_21467440.jpg",
                    "location": "nodes",
                    "id": "eb1048c9-ddfb-4c89-bd2c-cc947a5d110c",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 601,
                        "cm:lastThumbnailModification": [
                            "doclib:1452252620779"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 400
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T11:30:17.921+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 17894,
                        "encoding": "UTF-8"
                    },
                    "parentId": "32503920-9eda-4afa-a1fd-ac5ba586d7b3",
                    "createdAt": "2016-01-08T11:30:05.420+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "2.10_9687689.jpg",
                    "location": "nodes",
                    "id": "5b0bc838-a0df-4224-addc-db58e68ad405",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452252616776"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 402
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T11:30:20.673+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 17979,
                        "encoding": "UTF-8"
                    },
                    "parentId": "32503920-9eda-4afa-a1fd-ac5ba586d7b3",
                    "createdAt": "2016-01-08T11:30:06.368+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "2.12_a_12525652.jpg",
                    "location": "nodes",
                    "id": "c0657edf-f704-471a-bda9-59068cde24d5",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452252619940"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 400
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T11:30:20.829+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 21338,
                        "encoding": "UTF-8"
                    },
                    "parentId": "32503920-9eda-4afa-a1fd-ac5ba586d7b3",
                    "createdAt": "2016-01-08T11:30:07.260+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "2.12_b_7520458.jpg",
                    "location": "nodes",
                    "id": "472ec410-b9f6-4fcf-b14d-b16476317187",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452252620621"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 400
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T11:30:18.888+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 22343,
                        "encoding": "UTF-8"
                    },
                    "parentId": "32503920-9eda-4afa-a1fd-ac5ba586d7b3",
                    "createdAt": "2016-01-08T11:30:08.228+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 5_Tabla 2.1_a_19145085.jpg",
                    "location": "nodes",
                    "id": "aaab8a4e-1730-4dfc-b54a-bd57085a1d75",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452252617116"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 400
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T11:30:20.731+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 21382,
                        "encoding": "UTF-8"
                    },
                    "parentId": "32503920-9eda-4afa-a1fd-ac5ba586d7b3",
                    "createdAt": "2016-01-08T11:30:09.543+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 5_Tabla 2.1_b_25154856.jpg",
                    "location": "nodes",
                    "id": "e18f4fda-8c0e-406a-821b-29a20705512a",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452252620240"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 400
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T11:30:20.355+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 16008,
                        "encoding": "UTF-8"
                    },
                    "parentId": "32503920-9eda-4afa-a1fd-ac5ba586d7b3",
                    "createdAt": "2016-01-08T11:30:10.651+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 5_Tabla 2.1_c_21234632.jpg",
                    "location": "nodes",
                    "id": "14072704-1bb3-4d10-88f3-280bc9b74f86",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452252620154"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 439
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T11:30:20.760+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 37400,
                        "encoding": "UTF-8"
                    },
                    "parentId": "32503920-9eda-4afa-a1fd-ac5ba586d7b3",
                    "createdAt": "2016-01-08T11:30:11.752+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 9_14760279.jpg",
                    "location": "nodes",
                    "id": "ecba820f-7819-4115-bc3b-3daa11595322",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452252620413"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 600
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "modifiedAt": "2016-01-08T11:30:18.890+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 14380,
                        "encoding": "UTF-8"
                    },
                    "parentId": "32503920-9eda-4afa-a1fd-ac5ba586d7b3",
                    "createdAt": "2016-01-08T11:30:12.712+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "ariadna.alles@mheducation.com",
                        "displayName": "Ariadna Allés"
                    },
                    "name": "p. 12_16605104.jpg",
                    "location": "nodes",
                    "id": "2202e385-f136-40f8-a1f4-03ecc9c89974",
                    "properties": {
                        "macgh:id": "97884486097353",
                        "macgh:idioma": "Castellano",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "8448609735",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452252617092"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-04-15T22:00:00.000+0000",
                        "macgh:area_escolar": "ciclos",
                        "macgh:familia": "administracion",
                        "macgh:proy_edit_nombre": "Comunicación_y_atención_al_cliente_Libro_texto",
                        "exif:pixelYDimension": 321
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-15T15:18:16.550+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 12796,
                        "encoding": "UTF-8"
                    },
                    "parentId": "b22cd741-2204-42e8-8b61-3fa5e7be6bde",
                    "createdAt": "2016-01-15T15:18:00.466+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "1498076.jpg",
                    "location": "nodes",
                    "id": "5ac3c4bf-cc14-4f08-9e0f-32206c55010a",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452871096352"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 442
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-15T15:18:17.446+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 11868,
                        "encoding": "UTF-8"
                    },
                    "parentId": "b22cd741-2204-42e8-8b61-3fa5e7be6bde",
                    "createdAt": "2016-01-15T15:18:01.232+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "5837019.jpg",
                    "location": "nodes",
                    "id": "c00730ed-23f3-474d-8ffe-0b0b847aa16b",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 399,
                        "cm:lastThumbnailModification": [
                            "doclib:1452871097356"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 600
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-15T15:18:17.220+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 22601,
                        "encoding": "UTF-8"
                    },
                    "parentId": "b22cd741-2204-42e8-8b61-3fa5e7be6bde",
                    "createdAt": "2016-01-15T15:18:01.968+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "5967556.jpg",
                    "location": "nodes",
                    "id": "209eaff2-9aa2-4e48-bf34-223b345e4f86",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452871097086"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 399
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-15T15:18:16.871+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 19598,
                        "encoding": "UTF-8"
                    },
                    "parentId": "b22cd741-2204-42e8-8b61-3fa5e7be6bde",
                    "createdAt": "2016-01-15T15:18:02.670+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "7717691.jpg",
                    "location": "nodes",
                    "id": "ea6c6ead-49d4-4b9c-a83e-fc0631073bae",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452871096438"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 400
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-15T15:18:16.369+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 26912,
                        "encoding": "UTF-8"
                    },
                    "parentId": "b22cd741-2204-42e8-8b61-3fa5e7be6bde",
                    "createdAt": "2016-01-15T15:18:03.284+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "10913306.jpg",
                    "location": "nodes",
                    "id": "36827344-f996-4af7-aa32-ef346fbbdf45",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452871096028"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 400
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-15T15:18:16.338+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 31392,
                        "encoding": "UTF-8"
                    },
                    "parentId": "b22cd741-2204-42e8-8b61-3fa5e7be6bde",
                    "createdAt": "2016-01-15T15:18:03.978+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "11103569.jpg",
                    "location": "nodes",
                    "id": "cb68a5f3-35a2-4c6c-9282-2ea68a152dc0",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452871095792"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 400
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-15T15:18:16.347+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 27660,
                        "encoding": "UTF-8"
                    },
                    "parentId": "b22cd741-2204-42e8-8b61-3fa5e7be6bde",
                    "createdAt": "2016-01-15T15:18:04.688+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "12756462.jpg",
                    "location": "nodes",
                    "id": "c904f06e-dc97-4e36-b7be-dafcad97d48d",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452871095815"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 400
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-15T15:18:16.400+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 10568,
                        "encoding": "UTF-8"
                    },
                    "parentId": "b22cd741-2204-42e8-8b61-3fa5e7be6bde",
                    "createdAt": "2016-01-15T15:18:05.330+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "12838535.jpg",
                    "location": "nodes",
                    "id": "4464ef6c-d65b-4483-ac96-4e7c946d9d5e",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 601,
                        "cm:lastThumbnailModification": [
                            "doclib:1452871096036"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 432
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-15T15:18:16.823+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 47609,
                        "encoding": "UTF-8"
                    },
                    "parentId": "b22cd741-2204-42e8-8b61-3fa5e7be6bde",
                    "createdAt": "2016-01-15T15:18:06.109+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "14653475.jpg",
                    "location": "nodes",
                    "id": "575a7dd1-b14d-4835-8ef0-ac1741dd4bdb",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452871096420"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 399
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-15T15:18:16.443+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 18941,
                        "encoding": "UTF-8"
                    },
                    "parentId": "b22cd741-2204-42e8-8b61-3fa5e7be6bde",
                    "createdAt": "2016-01-15T15:18:06.910+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "14812530.jpg",
                    "location": "nodes",
                    "id": "6a38717a-4d9d-428d-9b8b-69c3d1737d3f",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452871096304"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 390
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-15T15:18:16.515+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 11909,
                        "encoding": "UTF-8"
                    },
                    "parentId": "b22cd741-2204-42e8-8b61-3fa5e7be6bde",
                    "createdAt": "2016-01-15T15:18:07.662+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "15163423.jpg",
                    "location": "nodes",
                    "id": "9f5ee27a-cee9-4cd1-a425-c52e2e7287c5",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452871096353"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 400
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-15T15:18:17.274+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 17122,
                        "encoding": "UTF-8"
                    },
                    "parentId": "b22cd741-2204-42e8-8b61-3fa5e7be6bde",
                    "createdAt": "2016-01-15T15:18:08.341+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "15660887.jpg",
                    "location": "nodes",
                    "id": "fa77c06e-b4dd-4184-8f3b-cae145803166",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452871097145"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 600
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-15T15:18:17.263+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 47444,
                        "encoding": "UTF-8"
                    },
                    "parentId": "b22cd741-2204-42e8-8b61-3fa5e7be6bde",
                    "createdAt": "2016-01-15T15:18:09.037+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "25138539.jpg",
                    "location": "nodes",
                    "id": "c45b0cce-0ba5-4f07-8ae6-1ca8e057a08c",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452871097067"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 600
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-15T15:18:17.175+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 23764,
                        "encoding": "UTF-8"
                    },
                    "parentId": "b22cd741-2204-42e8-8b61-3fa5e7be6bde",
                    "createdAt": "2016-01-15T15:18:09.774+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "30139299.jpg",
                    "location": "nodes",
                    "id": "392ac5ae-6037-4f2f-8583-16f6dc504631",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1452871096878"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 600
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-15T15:18:16.586+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/png",
                        "mimeTypeName": "PNG Image",
                        "sizeInBytes": 14097,
                        "encoding": "UTF-8"
                    },
                    "parentId": "b22cd741-2204-42e8-8b61-3fa5e7be6bde",
                    "createdAt": "2016-01-15T15:18:10.548+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "MAT2_LA_U8_04.PNG",
                    "location": "nodes",
                    "id": "feb1de61-bb40-497a-8000-c834b0c0b992",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 308,
                        "cm:lastThumbnailModification": [
                            "doclib:1452871096353"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 216
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-15T15:18:17.258+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/png",
                        "mimeTypeName": "PNG Image",
                        "sizeInBytes": 1568,
                        "encoding": "UTF-8"
                    },
                    "parentId": "b22cd741-2204-42e8-8b61-3fa5e7be6bde",
                    "createdAt": "2016-01-15T15:18:11.671+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "MAT2_LA_U8_05.PNG",
                    "location": "nodes",
                    "id": "8c6068b1-793d-4b2b-a26c-67b99a068686",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 60,
                        "cm:lastThumbnailModification": [
                            "doclib:1452871097106"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 62
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-15T15:18:16.515+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/png",
                        "mimeTypeName": "PNG Image",
                        "sizeInBytes": 3369,
                        "encoding": "UTF-8"
                    },
                    "parentId": "b22cd741-2204-42e8-8b61-3fa5e7be6bde",
                    "createdAt": "2016-01-15T15:18:12.275+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "MAT2_LA_U8_06.PNG",
                    "location": "nodes",
                    "id": "2e99bd70-fbe5-4cb9-9f32-9be57af88ba9",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 308,
                        "cm:lastThumbnailModification": [
                            "doclib:1452871096353"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 96
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-15T15:18:17.220+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/png",
                        "mimeTypeName": "PNG Image",
                        "sizeInBytes": 3509,
                        "encoding": "UTF-8"
                    },
                    "parentId": "b22cd741-2204-42e8-8b61-3fa5e7be6bde",
                    "createdAt": "2016-01-15T15:18:13.146+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "MAT2_LA_U8_07.PNG",
                    "location": "nodes",
                    "id": "c7ec0589-e6d4-4da8-a379-78b7630f5adc",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 308,
                        "cm:lastThumbnailModification": [
                            "doclib:1452871096944"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 96
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-15T15:18:17.088+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/png",
                        "mimeTypeName": "PNG Image",
                        "sizeInBytes": 11587,
                        "encoding": "UTF-8"
                    },
                    "parentId": "b22cd741-2204-42e8-8b61-3fa5e7be6bde",
                    "createdAt": "2016-01-15T15:18:13.820+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "MAT2_LA_U8_08.PNG",
                    "location": "nodes",
                    "id": "45b078fb-ab24-4f03-b285-10a74d0538b6",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 461,
                        "cm:lastThumbnailModification": [
                            "doclib:1452871096682"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 124
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-18T09:50:55.976+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 17085,
                        "encoding": "UTF-8"
                    },
                    "parentId": "12944cc3-fccb-405a-afe1-614605d5b1f1",
                    "createdAt": "2016-01-18T09:50:17.043+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "9964104.jpg",
                    "location": "nodes",
                    "id": "f5448ef7-9720-4bc3-8e79-743090469f75",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1453110655822"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 600
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-18T09:50:55.367+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 25021,
                        "encoding": "UTF-8"
                    },
                    "parentId": "12944cc3-fccb-405a-afe1-614605d5b1f1",
                    "createdAt": "2016-01-18T09:50:17.863+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "11185456.jpg",
                    "location": "nodes",
                    "id": "9c4dc150-1597-4001-8782-8b6e5f0c553c",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1453110655135"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 417
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-18T09:50:54.602+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 19560,
                        "encoding": "UTF-8"
                    },
                    "parentId": "12944cc3-fccb-405a-afe1-614605d5b1f1",
                    "createdAt": "2016-01-18T09:50:20.492+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "11499390.jpg",
                    "location": "nodes",
                    "id": "176b33a0-bc42-4d95-a8ba-57eea8498a6f",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1453110654430"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 280
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-18T09:50:55.355+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 32910,
                        "encoding": "UTF-8"
                    },
                    "parentId": "12944cc3-fccb-405a-afe1-614605d5b1f1",
                    "createdAt": "2016-01-18T09:50:23.996+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "12369620.jpg",
                    "location": "nodes",
                    "id": "b42afde4-6274-49c3-8c20-782e87a581c5",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1453110655065"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 424
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-18T09:50:55.325+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 25695,
                        "encoding": "UTF-8"
                    },
                    "parentId": "12944cc3-fccb-405a-afe1-614605d5b1f1",
                    "createdAt": "2016-01-18T09:50:37.973+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "12489992.jpg",
                    "location": "nodes",
                    "id": "818a02ee-3812-4b89-a411-f9ff09f3a0d6",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 424,
                        "cm:lastThumbnailModification": [
                            "doclib:1453110655073"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 600
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-18T09:50:55.348+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 37664,
                        "encoding": "UTF-8"
                    },
                    "parentId": "12944cc3-fccb-405a-afe1-614605d5b1f1",
                    "createdAt": "2016-01-18T09:50:38.855+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "13748023.jpg",
                    "location": "nodes",
                    "id": "3cdb17eb-e2ed-4a3b-8ecb-0e42bf6e85e5",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1453110655065"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 450
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-18T09:50:55.074+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 27553,
                        "encoding": "UTF-8"
                    },
                    "parentId": "12944cc3-fccb-405a-afe1-614605d5b1f1",
                    "createdAt": "2016-01-18T09:50:39.515+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "14943998.jpg",
                    "location": "nodes",
                    "id": "3ae019d4-4c1b-4e14-a2c7-16bf081e42ad",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1453110654902"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 400
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-18T09:50:55.615+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 15024,
                        "encoding": "UTF-8"
                    },
                    "parentId": "12944cc3-fccb-405a-afe1-614605d5b1f1",
                    "createdAt": "2016-01-18T09:50:40.216+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "16290381.jpg",
                    "location": "nodes",
                    "id": "324118f1-86d8-4518-815f-a6296c124467",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 552,
                        "cm:lastThumbnailModification": [
                            "doclib:1453110655276"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 600
                    }
                }
            },
            {
                "entry": {
                    "isFile": true,
                    "createdByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "modifiedAt": "2016-01-18T09:50:57.621+0000",
                    "nodeType": "macgh:media",
                    "content": {
                        "mimeType": "image/jpeg",
                        "mimeTypeName": "JPEG Image",
                        "sizeInBytes": 36563,
                        "encoding": "UTF-8"
                    },
                    "parentId": "12944cc3-fccb-405a-afe1-614605d5b1f1",
                    "createdAt": "2016-01-18T09:50:40.863+0000",
                    "isFolder": false,
                    "search": {
                        "score": 1.0
                    },
                    "modifiedByUser": {
                        "id": "elsa.tebar@mheducation.com",
                        "displayName": "Elsa Tébar"
                    },
                    "name": "16559790.jpg",
                    "location": "nodes",
                    "id": "06875df1-e935-4e3a-8913-45af4e322989",
                    "properties": {
                        "macgh:id": "9788448609313",
                        "macgh:idioma": "Catalán",
                        "cm:versionLabel": "1.0",
                        "macgh:isbn": "844860931X",
                        "macgh:division": "escolar",
                        "macgh:anyo": "2016",
                        "exif:pixelXDimension": 600,
                        "cm:lastThumbnailModification": [
                            "doclib:1453110657516"
                        ],
                        "macgh:tipo_proyecto": "digitalypapel",
                        "macgh:fecha_publicacion": "2016-03-30T22:00:00.000+0000",
                        "macgh:area_escolar": "secundaria",
                        "macgh:familia": "sin_familia",
                        "macgh:proy_edit_nombre": "Matemàtiques_2_Libro_texto",
                        "exif:pixelYDimension": 499
                    }
                }
            }
        ]
    }
}

export default mockup_external
