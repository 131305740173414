import React from "react";
import Container from "@material-ui/core/Container";


function PanelCDN({classes}) {
    return (
        <Container maxWidth="lg">demo</Container>
    );
}

export default PanelCDN;
